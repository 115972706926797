import { commonService } from '../../../_services/common.service';
import commonJs from '../../../mixins/common'
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

function multiplyLot(qty, info) {
    let lotSize = info?.lotSize
    let exch = info?.exchange
    let mqty = exch == 'MCX' ? (qty * lotSize) : qty
    return Math.abs(mqty)
}

const state = {
    orderType: "Regular",
    priceType: 'MKT',
    oPriceType: 'Market',
    pcodes: [],
    isPrice: false,
    isTrigprice: false,
    orderRadioGroup: [],
    priceRadioGroup: [],
    contractInfo: '',
    // 
    qtyModel: 0,
    priceModel: 0,
    trigPriceModel: 0,
    discQtyModel: 0,
    targetPriceModel: 0,
    stopLossPriceModel: 0,
    tailingSlPriceModel: 0,
    marginData: [],
    isAddOrExit: '',
    currentScripData: '',
    currentPage: '',
    isModify: false,
    isRepeat: false,
    validity: 'DAY',
    owSubToken: '',
    currentLtp: '',
    currentLtpForPrice: '',
    isLocalPreference: false,
    isLoader: false,
    productCode: 'MIS',
    //
    validityGroup: [
        { name: 'Day', value:'Day', id: 0 , active: true, tooltip: 'Regular day order' },
        { name: 'IOC', value:'IOC', id: 0 , active: false, tooltip: 'Immediate or Cancel' }
    ],
    currentLtpPchg: '0.00',
    currentLtpChg: '0.00',
    isStoplossChecked: false,
    isTargetChecked: false,
    isVisible: false,
    isNudge: false,
    nudgeData: [],
    isAsmGsmConfirm: false,
    isBoChecked: false,
    isCoChecked: false
}

const actions = {
    async getContractInfo({ state, commit, dispatch, rootGetters, rootState }, payload) {
        try {
            let json = {
                "token": payload.token,
                "exch": payload.exchange
            }
            let response = await commonService.getContractInfo(json)
            
            if (response.status == 200 && response.data?.result && response.data.status != 'Not ok') {
                commit('setContractInfo', { data: response.data?.result , from : payload?.from })
                if(!rootGetters['getIsConvertDialog']) {
                    commit('setIsVisible', true)
                    rootState.buysellpopup.moreOption = false
                }
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async changeOrderByTab({ state, commit, dispatch }) {   
        switch (state.orderType) {
            case "Regular": //Regular Order // We have taken (regular = amo) except for validity
            case "AMO": {
                if (state.priceType == "L") {
                    this.commit('ow/setIsPrice', true)
                    this.commit('ow/setIsTrigprice', false)
                } else if (state.priceType == "MKT") {
                    this.commit('ow/setIsPrice', false)
                    this.commit('ow/setIsTrigprice', false)
                } else if (state.priceType == "SL") {
                    this.commit('ow/setIsPrice', true)
                    this.commit('ow/setIsTrigprice', true)
                } else if (state.priceType == "SL-M") {
                    this.commit('ow/setIsPrice', false)
                    this.commit('ow/setIsTrigprice', true)
                }
                break;
            }
            case "CO": {
                if (state.priceType == "L") {
                    this.commit('ow/setIsPrice', true)
                    this.commit('ow/setIsTrigprice', false)
                } else if (state.priceType == "MKT") {
                    this.commit('ow/setIsPrice', false)
                    this.commit('ow/setIsTrigprice', false)
                } else if (state.priceType == "SL") {
                    this.commit('ow/setIsPrice', true)
                    this.commit('ow/setIsTrigprice', true)
                }
                break;
            }
            case "BO": {
                // bracket Order validation
                if (state.priceType == "L") {
                    this.commit('ow/setIsPrice', true)
                    this.commit('ow/setIsTrigprice', false)
                } else if (state.priceType == "SL") {
                    this.commit('ow/setIsPrice', true)
                    this.commit('ow/setIsTrigprice', true)
                } else if (state.priceType == "MKT") {
                    this.commit('ow/setIsPrice', false)
                    this.commit('ow/setIsTrigprice', false)
                }
                break;
            }
        }
    },

    async getMargin({ state, commit, dispatch, rootGetters, }) {
        commit('setMarginLoader', true)
        try {
            let orderType = state.orderType == 'CO' ? 'Cover' : state.orderType == 'BO' ? 'Bracket' : state.orderType
            let exch = state.contractInfo[0]?.scrips[0]?.exchange
            let json = {
                "exchange": exch,
                "token": state.contractInfo[0]?.scrips[0]?.token,
                "tradingSymbol": state.contractInfo[0]?.scrips[0]?.tradingSymbol,
                "qty": exch == 'MCX' ? state.qtyModel * Number(state.contractInfo[0]?.scrips[0]?.lotSize) : state.qtyModel,
                "price":  state.priceModel,
                "product": state.productCode,
                "transType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'B' : 'S',
                "priceType": state?.priceType,
                "orderType": orderType,
                "triggerPrice": state.trigPriceModel,
                "stopLoss": state.stopLossPriceModel,
                "ltp": orderType == 'Cover' && state.priceType == "MKT" ? state.currentLtp : "",
                "snonum": (orderType == "Bracket" || orderType == "Cover") && state.isModify
                    ? state.currentScripData?.snoOrderNumber
                    : undefined,
                "orderNo": (orderType == 'Bracket' || orderType == 'Cover') && state.isModify
                    ? state.currentScripData?.orderNo
                    : undefined,
            }
            if(orderType == 'Cover' || orderType == 'Bracket') {
                json["target"] = state.targetPriceModel
            }
            if(state.isModify && state.currentPage != 'basket') {
                json["openOrderQty"] = state.currentScripData?.qty
                json["openOrderprice"] = state.currentScripData?.price
                if(state.currentScripData?.triggerPrice)
                json["openOrderTriggerPrice"] = state.currentScripData?.triggerPrice
            }
            let response = await commonService.getMargin(json)
            if (response.status == 200 && response.data?.result) {
                commit('setMarginData', response.data?.result)
            } else {
                commit('setMarginData', [])
            }
        } catch (error) {
            commit('setMarginLoader', false)
            commit('setMarginData', [])
            errHandle.statusCodeCheck(error)
        }
        commit('setMarginLoader', false)
    },

    async placeOrder({ state, commit, dispatch, rootGetters, }, payload) {
        try {
            commit('setIsLoader', true)
            let orderType = state.orderType == 'CO' ? 'Cover' : state.orderType == 'BO' ? 'Bracket' : state.orderType
            let json = [{
                "exchange": state.contractInfo[0]?.scrips[0]?.exchange,
                "tradingSymbol": state.contractInfo[0]?.scrips[0]?.tradingSymbol,
                "qty": multiplyLot(state.qtyModel , state.contractInfo[0]?.scrips[0]),
                "price": state.isPrice ? state.priceModel : 0,
                "product": state.productCode,
                "transType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'B' : 'S',
                "priceType": state.priceType,
                "orderType" : orderType,
                "ret": state.validity,
                "triggerPrice": state.isTrigprice ? state.trigPriceModel : 0,
                "disclosedQty": state?.discQtyModel,
                "mktProtection": "",
                "target": !state.targetPriceModel ? 0 : state.targetPriceModel,
                "stopLoss": !state.stopLossPriceModel ? 0 : state.stopLossPriceModel,
                "trailingPrice": !state.tailingSlPriceModel ? 0 : state.tailingSlPriceModel,
                "source" : "WEB",
                "token" : state.contractInfo[0]?.scrips[0]?.token
            }]
            if(state.currentPage == 'basket' && rootGetters['basket/getBasketOwType'] != 'Edit') {
                json[0]["token"] = state.contractInfo[0]?.scrips[0]?.token
                
                dispatch('basket/addScripInBasket', json, { root:true })
            }else if(state.currentPage == 'basket' && rootGetters['basket/getBasketOwType'] == 'Edit') {
                json[0]["token"] = state.contractInfo[0]?.scrips[0]?.token
                json[0]["id"] = rootGetters['basket/getCurrentScripData'].id
                json[0]["lotSize"] = state.contractInfo[0]?.scrips[0]?.lotSize
                dispatch('basket/editScripInBasket', json, { root:true })
            } else {
                let response = await commonService.placeOrder(json)
                if (response.status == 200 && response.data[0].message == "Success") { }
                else if(response.status == 200 && response.data[0].message == "Unauthorized") {
                    errHandle.unauthorizeHandle()
                } else {
                    notify({ group: "auth",  clean: true });
                    notify({ group: 'auth', type: 'error', title: `${ response.data[0].message }` })
                }
            }
        } catch (error) {
            commit('setIsLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('setIsLoader', false)
    },

    async modifyOrder({ state, commit, dispatch, rootGetters, }, payload) {
        try {
            commit('setIsLoader', true)
            let json = {
                "exchange": state.contractInfo[0]?.scrips[0]?.exchange,
                "tradingSymbol": state.contractInfo[0]?.scrips[0]?.tradingSymbol,
                "orderNo": state.currentScripData?.orderNo,
                "qty": multiplyLot(state.qtyModel, state.contractInfo[0]?.scrips[0])?.toString(),
                "ret": state.validity,
                "priceType": state.priceType,
                "transType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'B' : 'S',
                "price":  state.isPrice ? state.priceModel?.toString() : '',
                "product": state.productCode,
                "triggerPrice": state.trigPriceModel == 0 ? '' : state.isTrigprice ? state.trigPriceModel : '',
                "disclosedQty": state?.discQtyModel,
                "mktProtection": "",
                "target": state.targetPriceModel == 0 ? '' : state.targetPriceModel,
                "stopLoss": state.stopLossPriceModel == 0 ? '' : state.stopLossPriceModel,
                "trailingPrice": state.tailingSlPriceModel == 0 ? '' : state.tailingSlPriceModel,
                "token" : state.contractInfo[0]?.scrips[0]?.token
            }
            let response = await commonService.modifyOrder(json)
            if (response.status == 200 && response.data.message == "Success") { }
            else if (response.status == 200 && response.data[0].message == "Unauthorized") {
                errHandle.unauthorizeHandle()
            } else {
                notify({ group: "auth",  clean: true });
                notify({ group: 'auth', type: 'error', title: `${ response.data[0].message }` })
            }
        } catch (error) {
            commit('setIsLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('setIsLoader', false)
    },

    async unsubScribe({ dispatch, rootGetters }) {
        if (rootGetters['ws/getOtherSubscription']) {
            let temp = {
                sub: rootGetters['ws/getOtherSubscription'],
                stage: 'esc'
            }
            dispatch('ws/ortherSubscription', temp, { root: true })
        }
    },

    async wsOwSubscription({ state, commit, dispatch }) {
        var tempData = {
            sub: `${state.contractInfo[0]?.scrips[0]?.exchange}|${state.contractInfo[0]?.scrips[0]?.token}`,
            stage: 'initial'
        }
        commit('setOwSubToken',tempData.sub)
        dispatch('ws/ortherSubscription', tempData, { root: true })
    },

    async setOrderPreference({ state, commit, dispatch }) {
        if(localStorage.getItem('orderPreference') && state.currentPage != 'positions' && state.currentPage != 'holdings') {
            let { variety, product, pcode } = JSON.parse(localStorage.getItem('orderPreference'))
                if(variety && product && pcode ) {
                    commit('setPriceType', product)
                    await commit('setOrderType', variety ? variety : 'Regular')
                    if (pcode == 'NRML' && (state.currentExchange == 'NSE' || state.currentExchange == 'BSE')) {
                        pcode = 'CNC'
                    } else if (pcode == 'CNC' && (state.currentExchange != 'NSE' && state.currentExchange != 'BSE')) {
                        pcode = 'NRML'
                    }
                    commit('setProductCode', pcode)
                } else {
                    dispatch('defaultPreference')
                }
        } else {
            dispatch('defaultPreference')
        }
        commit('setStopLossPrice', 0)
        commit('setTargetPrice', 0)
    },
  
    async defaultPreference({ state, commit, rootState }) {
        if(state.currentPage == 'positions') {
            await commit('setOrderType', 'Regular')
            commit('setPriceType', 'MKT')
            commit('setProductCode', state.currentScripData.product || 'MIS')
        } else if(state.currentPage == 'holdings') {
            await commit('setOrderType', 'Regular')
            commit('setPriceType', 'MKT')
            commit('setProductCode', "CNC")
        } else {
            await commit('setOrderType', 'Regular')
            commit('setPriceType', 'L')
            commit('setProductCode', "MIS")
        }
        commit('setStopLossPrice', 0)
        commit('setTargetPrice', 0)
    },

    modifyPreference({ state, commit }) {
        let ot = state.currentScripData?.orderType
        commit('setOrderType', ot == 'Cover' ? 'CO' : ot == 'Bracket' ? 'BO' : ot)
        commit('setPriceType', state.currentScripData?.priceType)
        commit('setProductCode', state.currentScripData?.product)
        // 
        if (ot == 'Cover') {
            commit('setStopLossPrice', state.currentScripData?.stopLoss)
        }
        if (ot == 'Bracket') {
            commit('setTargetPrice', state.currentScripData?.target)
            commit('setStopLossPrice', state.currentScripData?.stopLoss)
        }

        
        if(ot != 'Cover' && ot != 'Bracket') {
            commit('setStopLossPrice', 0) 
        }
        if(ot != 'Bracket') {
            commit('setTargetPrice', 0)
        } 
    },

    repeatPreference({state, commit}) {
        let ot = state.currentScripData?.orderType
        commit('setOrderType', ot == 'Cover' ? 'CO' : ot == 'Bracket' ? 'BO' : ot)
        commit('setPriceType', state.currentScripData?.priceType)
        commit('setProductCode', state.currentScripData?.product)

        if(ot == 'Cover') {
            commit('setStopLossPrice', state.currentScripData?.stopLoss)
        } 
        if (ot == 'Bracket') {
            commit('setTargetPrice', state.currentScripData?.target)
            commit('setStopLossPrice', state.currentScripData?.stopLoss)
        } 

        if(ot != 'Cover' && ot != 'Bracket') {
            commit('setStopLossPrice', 0) 
        }
        if(ot != 'Bracket') {
            commit('setTargetPrice', 0)
        }
    },

    async getBrokerageDetails({ state, commit, dispatch, rootGetters, rootState }) {
        try {
            commit('brokerage/setLoader', true, { root : true })
            let insType = state.contractInfo[0]?.scrips[0]?.insType;

            let qty = state.qtyModel?.toString();
            let json = {
                "user": rootGetters['login/getUCC'],
                "price": String(parseFloat(state.priceModel).toFixed(2)),
                "qty": qty,
                "token": String(state.contractInfo[0]?.scrips[0]?.token),
                "lotSize": String(state.contractInfo[0]?.scrips[0]?.lotSize),
                "plan": "IBT",
                "segment": state.currentExchange,
                "type": state.productCode == 'MIS' ? 'INTRADAY' : 'DELIVERY' ,
                "instrumentType": state.currentExchange == "NSE" || state.currentExchange == "BSE" ? "EQ" : state.contractInfo[0]?.scrips[0]?.insType,
                "transactionType": rootGetters['getBuysellpopup'].bsselected === 0 ? 'buy' : 'sell',
                "symbol": state.contractInfo[0]?.scrips[0]?.symbol
            }
            let resp = await commonService.getBrokerageDetails(json)
            commit('brokerage/setBrokeragePayload', { data:json, ci: state.contractInfo[0]?.scrips[0], priceType: state.priceType }, { root: true })
            if (resp.data.message == "Success") {
                commit('brokerage/setBrokerageDetails', resp.data.result[0], { root: true })
            } else {
                commit('brokerage/setBrokerageDetails', [], { root: true })
            }
        } catch (error) {
            commit('brokerage/setLoader', false, { root : true })
            errHandle.statusCodeCheck(error)
        }
        commit('brokerage/setLoader', false, { root : true })
    },

    async setDefaultValues({ state, commit, dispatch, rootGetters, rootState }) {
        // set qty model value based ord type
        dispatch('setDefaultPrice')
        dispatch('setDefaultTrigPrice')
        if(state.currentScripData?.orderType == 'Cover') {
            dispatch('setDefaultStopLossPrice')
        }
        let qty = state.contractInfo[0]?.scrips[0]?.lotSize
        let discQty = 0
        if (state.isAddOrExit == 'exit' && state.currentPage == 'holdings') {
            qty = state.currentScripData?.netQty
        } else if (state.isAddOrExit == 'exit' && state.currentPage == 'positions') {
            qty = state.currentScripData?.netQty
        }
        qty = !qty ? 0 : state.currentExchange == 'MCX' ? (qty/qty) : qty
        if(state.isRepeat || state.isModify) {
            qty = state.currentScripData.exchange == "MCX" ?  state.currentScripData?.qty /state.currentScripData?.lotSize : state.currentScripData?.qty
            discQty = state.currentScripData.disclosedQty
        }
        commit('setQtyModel', Math.abs(parseFloat(qty)))
        commit('setDiscQtyModel', discQty)
        commonJs.focusQty()
    },

    async setDefaultPrice({state, commit, dispatch}, payload) {
        // set default price
        if(state.currentLtpForPrice == '' && !state.isModify && !state.isRepeat) {
            commit('setPriceModel', commonJs.toFixed(state.contractInfo[0]?.scrips[0]?.pdc))
        } else {
            if(state.isModify || state.isRepeat && state.priceType != "MKT" && state.priceType != "SL-M") {
                commit('setPriceModel', state.currentScripData?.price)
            } else {
                commit('setPriceModel', commonJs.toFixed(state.currentLtpForPrice))
            }
        }
    },

    async setDefaultTrigPrice({state, commit, dispatch}, payload) {
        // set default trigger price
        if(state.currentLtpForPrice == '' && !state.isModify && !state.isRepeat) {
            commit('setTrigPriceModel', commonJs.toFixed(state.contractInfo[0]?.scrips[0]?.pdc))
        } else {
            if(state.isModify || state.isRepeat) {
                commit('setTrigPriceModel', state.currentScripData?.triggerPrice ? state.currentScripData?.triggerPrice :  state.currentLtpForPrice)
            } else {
                commit('setTrigPriceModel', commonJs.toFixed(state.currentLtpForPrice))
            }
        }
    },

    async setDefaultStopLossPrice({state, commit, dispatch}, payload) {
        if(state.isModify) {
            commit('setStopLossPrice', state.currentScripData?.stopLoss ? state.currentScripData?.stopLoss :  0)
        } else {
            commit('setStopLossPrice', 0)
        }
    },

};

const mutations = {
    setOrderType(state, payload) {
        state.orderType = payload
        this.commit('ow/setOrderRadioGroup', state.orderType)
        this.commit('ow/setPriceRadioGroup', state.orderType)
        this.commit("ow/setIsBoChecked", state.orderType == "BO");
        this.commit("ow/setIsCoChecked", state.orderType == "CO");
    },

    setPcodes(state, payload) {
        state.pcodes = []
        let exchange = state.contractInfo[0]?.scrips[0]?.exchange
        
        let obj = exchange == 'NSE' || exchange == 'BSE' ? { name:'Long Term', key: 'CNC', id:1, active: true, tooltip: 'CashNCarry: Longterm investment.Requires full upfront margin' } : { name:'Positional', key: 'NRML', id:1, active: true, tooltip: 'Normal: Carry forward positions overnight.' }
        state.pcodes.push(obj)
        state.pcodes.push({ name:'Intraday', key: 'MIS', id:0, active: false, tooltip: 'Margin Intraday Squareoff: Requires lower margin. Has to be exited before market close.' })
    },

    setOrderRadioGroup(state, payload) {
        let og = [{
            name: 'Regular',
            value: 'Regular',
            id: 'bs-regular',
            tooptip: 'Regular order'
        },
        {
            name: 'Cover',
            value: 'CO',
            id: 'bs-co',
            tooptip: 'Cover order'
        },
        {
            name: 'Bracket',
            value: 'BO',
            id: 'bs-bo',
            tooptip: 'Bracket order'
        },
        {
            name: 'AMO',
            value: 'AMO',
            id: 'bs-amo',
            tooptip: 'After Market Order(for the next day)'
        }]
        state.orderRadioGroup = og
    },

    setPriceRadioGroup(state, orderType) {
        let regularData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss' },
        { name: 'SL-M', value: 'SL-M', id: 'bs-SL-M', active: false, tooltip: 'at market price with stoploss' }]

        let coData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss' },
        ]

        let boData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: true , tooltip: 'at preferred price'},
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: false, tooltip: 'at Market price' },
        { name: 'SL', value: 'SL', id: 'bs-Stop-Loss', active: false, tooltip: 'at preferred price with a stoploss'},
        ]

        let amoData = [{ name: 'Limit', value: 'L', id: 'bs-Limit', active: false, tooltip: 'at preferred price' },
        { name: 'Market', value: 'MKT', id: 'bs-Market', active: true, tooltip: 'at Market price' },
        { name: 'SL', value: 'SL', id: 'bs-Stop-Loss' , active: false, tooltip: 'at preferred price with a stoploss' }]

        if (orderType == 'Regular') {
            state.priceRadioGroup = regularData;
        } else if (orderType == 'CO') {
            state.priceRadioGroup = coData;
        } else if (orderType == 'BO') {
            state.priceRadioGroup = boData;
        } else if (orderType == 'AMO') {
            state.priceRadioGroup = amoData;
        }

        let optType = state.contractInfo[0]?.scrips[0]?.insType
        let isNFO = ((state.currentExchange == 'NFO' || state.currentExchange == 'BFO') && optType == "OPTIDX") || state.currentExchange == 'CDS' || state.currentExchange == 'BCD'
        if(isNFO) {
            state.priceRadioGroup = amoData
            
            if(orderType == 'CO' || orderType == 'BO' ) {
                state.priceRadioGroup[2].disabled = true
            }
        }

        // 
        let priceType = ''
        
        state.priceRadioGroup.forEach(el => {
            if(el.active) {
                priceType = el.value
            }
            if(state.currentExchange == 'MCX' && state.isModify && (el.name == "SL" || el.name == "SL-M") ) {
                el.disabled = true
            } else {
                el.disabled = false
            }
        });

        let localPref =  JSON.parse(localStorage.getItem('orderPreference'))
        if(state.currentPage != 'positions' && state.currentPage != 'holdings' && !state.isRepeat && !state.isModify) {
            if(orderType == 'CO' && (priceType == 'MKT' || priceType == 'SL-M')) {
                this.commit('ow/setPriceType', 'L')
            } else if(orderType == 'BO' && (priceType == 'SL' || priceType == 'SL-M')) {
                this.commit('ow/setPriceType', 'L')
            } else if((state.priceType == 'SL-M' && isNFO ) || ( state.priceType == 'SL-M' && orderType == 'AMO')) {
                this.commit('ow/setPriceType', 'SL')
            } else if(localPref?.product) {
                this.commit('ow/setPriceType', localPref?.product)
            } else {
                this.commit('ow/setPriceType', priceType)
            }
        }
    },

    setContractInfo(state, payload) {
        state.contractInfo = payload.data
        if(state.contractInfo[0].scrips[0].prompt) {
            this.commit('ow/setNudgeData', state.contractInfo[0].scrips[0].prompt)
            this.commit('ow/setIsNudge', true)
        } else {
            this.commit('ow/setNudgeData', [])
            this.commit('ow/setIsNudge', false)
        }
        this.commit('ow/setCurrentExchange', state.contractInfo[0]?.scrips[0]?.exchange)
        this.commit('ow/setCurrentLtpForPrice', '')
        this.dispatch('ow/wsOwSubscription')
        this.commit('ow/setPcodes')


        if(payload.from != 'convert') {
            errHandle.closeAllModals()
            this.commit('setIsOpenOw', true)
        }

        // set preference
        let localPref =  localStorage.getItem('orderPreference')
        if (state.isLocalPreference && localPref && !state.isModify && !state.isRepeat) {
            this.dispatch("ow/setOrderPreference")
        } else if(state.isModify) {
            this.dispatch('ow/modifyPreference')
        } else if(state.isRepeat) {
            this.dispatch('ow/repeatPreference')
        } else if(!state.isModify && !state.isRepeat) {
            this.dispatch('ow/defaultPreference')
        }
        let tickers = window.tickers
        let ltp = 0 ;
        let token = state.contractInfo[0]?.scrips[0]?.token
        let exchange = state.contractInfo[0]?.scrips[0]?.exchange
        let wsToken = `${exchange}|${token}`
        if(tickers && tickers[wsToken]?.lp) {
            ltp = tickers[wsToken]?.lp
            if(ltp) {
                this.commit('ow/setCurrentLtp', ltp)
                this.commit('ow/setCurrentLtpForPrice', ltp)
            }
        }
    },

    setInputModelValues(state, payload) {
        state.qtyModel = '',
        state.priceModel = '',
        state.trigPriceModel = ''
    },

    setIsPrice(state, payload) {
        state.isPrice = payload
    },

    setIsTrigprice(state, payload) {
        state.isTrigprice = payload
    },

    setQtyModel(state, payload) {
        state.qtyModel = payload
    },

    setPriceModel(state, payload) {
        state.priceModel = payload
    },

    setTrigPriceModel(state, payload) {
        state.trigPriceModel = payload
    },

    setDiscQtyModel(state, payload) {
        state.discQtyModel = payload
    },

    setMarginData(state, payload) {
        state.marginData = payload
    },

    setIsAddOrExit(state, payload) {
        state.isAddOrExit = payload
        if (payload == 'modify') {
            state.isModify = true
        } else {
            state.isModify = false
        }
        if (payload == 'repeat') {
            state.isRepeat = true
        } else {
            state.isRepeat = false
        }
    },

    setCurrentScripData(state, payload) {
        state.currentScripData = payload
    },

    setCurrentPage(state, payload) {
        state.currentPage = payload
    },

    setTargetPrice(state, payload) {
        state.targetPriceModel = payload
    },

    setStopLossPrice(state, payload) {
        state.stopLossPriceModel = payload
    },

    setTailingSlPrice(state, payload) {
        state.tailingSlPriceModel = payload
    },
    
    setOwSubToken(state, payload) {
        state.owSubToken = payload
    },

    setCurrentLtp(state, payload) {
        state.currentLtp = payload
    },

    setCurrentLtpForPrice(state, payload) {
        state.currentLtpForPrice = payload
    },

    setIsLocalPreference(state, payload) {
        state.isLocalPreference = payload
    },

    setCurrentExchange(state, payload) {
        state.currentExchange = payload
    },

    setMarginLoader(state, payload) {
        state.marginLoader = payload
    },
    setIsLoader(state, payload) {
        state.isLoader = payload
    },

    setProductCode(state, payload) {
        state.productCode = payload
        this.commit('ow/setPcodeActive', payload)
    },

    setValidity(state, payload) {
        state.validity = payload
        this.commit('ow/setValidityActive', payload)
    },

    setPriceType(state, payload) {
        state.priceType = payload
        this.commit('ow/setPriceTypeActive', payload)
    },

    // 
    setPriceTypeActive(state, value) {
        let data = state.priceRadioGroup
        if(!data?.length) return;
        let found = false;
        data.forEach(el => {
            el.active = (el.value == value);
            if (el.active) {
                state.priceType = el.value
                found = true
            }
        });
        if (!found && data.length > 0) {
            data[0].active = true;
            state.priceType = data[0].value;
        }
    },

    setValidityActive(state, value) {
        if(!state.validityGroup.length) return;
        state.validityGroup.forEach(el => {
            if (value == el.name) {
                el.active = true
                state.validity = el.value
            } else {
                el.active = false
            }
        });
    },

    setPcodeActive(state, key) {
        state.pcodes.forEach(el => {
            if (key == el.key) {
                el.active = true
                state.productCode = el.key
            } else {
                el.active = false
            }
        });
    },

    setCurrentLtpPchg(state, payload) {
        state.currentLtpPchg = payload
    },

    setCurrentLtpChg(state, payload) {
        state.currentLtpChg = payload
    },
    setIsStoplossChecked(state, payload) {
        state.isStoplossChecked = payload
    },
    setIsTargetChecked(state, payload) {
        state.isTargetChecked = payload
    },
    setIsVisible(state, payload){
        state.isVisible = payload
    },
    setIsNudge(state, payload) {
        state.isNudge = payload
    },
    setNudgeData(state, payload) {
        state.nudgeData = payload
    },
    setIsAsmGsmConfirm(state, payload) {
        state.isAsmGsmConfirm = payload
    },
    reset_state(state) {
        Object.assign(state,{
            orderType: "Regular",
            priceType: 'MKT',
            oPriceType: 'Market',
            pcodes: [],
            isPrice: false,
            isTrigprice: false,
            orderRadioGroup: [],
            priceRadioGroup: [],
            contractInfo: '',
            qtyModel: 0,
            priceModel: 0,
            trigPriceModel: 0,
            discQtyModel: 0,
            targetPriceModel: 0,
            stopLossPriceModel: 0,
            tailingSlPriceModel: 0,
            marginData: [],
            isAddOrExit: '',
            currentScripData: '',
            currentPage: '',
            isModify: false,
            isRepeat: false,
            validity: 'DAY',
            owSubToken: '',
            currentLtp: '',
            currentLtpForPrice: '',
            isLocalPreference: false,
            isLoader: false,
            productCode: 'MIS',
            validityGroup: [
                { name: 'Day', value:'Day', id: 0 , active: true, tooltip: 'Regular day order' },
                { name: 'IOC', value:'IOC', id: 0 , active: false, tooltip: 'Immediate or Cancel' }
            ],
            currentLtpPchg: '0.00',
            currentLtpChg: '0.00',
            isStoplossChecked: false,
            isTargetChecked: false,
            isVisible: false,
            isNudge: false,
            nudgeData: [],
            isAsmGsmConfirm: false
        });
    },
    setIsBoChecked(state, payload) {
        state.isBoChecked = payload
    },
    setIsCoChecked(state, payload) {
        state.isCoChecked = payload
    }
};

const getters = {
    getOrderType: (state) => state.orderType,
    getPriceType: (state) => state.priceType,
    getIsPrice: (state) => state.isPrice,
    getIsTrigprice: (state) => state.isTrigprice,
    getOrderRadioGroup: (state) => state.orderRadioGroup,
    getPriceRadioGroup: (state) => state.priceRadioGroup,
    getContractInfo: (state) => state.contractInfo,
    getQtyModel: (state) => state.qtyModel,
    getPriceModel: (state) => state.priceModel,
    getTrigPriceModel: (state) => state.trigPriceModel,
    getDiscQtyModel: (state) => state.discQtyModel,
    getMarginData: (state) => state.marginData,
    getIsAddOrExit: (state) => state.isAddOrExit,
    getCurrentPage: (state) => state.currentPage,
    getIsModify: (state) => state.isModify,
    getTargetPrice: (state) => state.targetPriceModel,
    getStopLossPrice: (state) => state.stopLossPriceModel,
    getTailingSlPrice: (state) => state.tailingSlPriceModel,
    getValidity: (state) => state.validity,
    getLotSize: (state) => state.contractInfo[0]?.scrips[0]?.lotSize,
    getOwSubToken: (state) => state.owSubToken,
    getCurrentLtp: (state) => state.currentLtp,
    getPcodes: (state) => state.pcodes,
    currentExchange: (state) => state.currentExchange,
    marginLoader: (state) => state.marginLoader,
    isLoader: (state) => state.isLoader,
    // 
    getValidityGroup: (state) => state.validityGroup,
    getProductCode: (state) => state.productCode,
    getCurrentLtpPchg: (state) => state.currentLtpPchg,
    getCurrentLtpChg: (state) => state.currentLtpChg,
    getIsStoplossChecked: (state) => state.isStoplossChecked,
    getIsTargetChecked: (state) => state.isTargetChecked,
    getCurrentLtpForPrice: (state) => state.currentLtpForPrice,
    getIsNudge: (state) => state.isNudge,
    getNudgeData : (state) => state.nudgeData,
    getLotSizeClone : (state) => state.contractInfo[0]?.scrips[0]?.lotSize,
    getIsAsmGsmConfirm: (state) => state.isAsmGsmConfirm,
    getIsBoChecked: (state) => state.isBoChecked,
    getIsCoChecked: (state) =>  state.isCoChecked
};

const ow = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default ow